import bg from "../../assets/images/bg_home.png";
import bgMobile from "../../assets/images/bg_home_mobile.png";
import doctor from "../../assets/images/doctor.png";
import virtualChat from "../../assets/images/virtual_chat.png";
import BlueButton from "../partials/BlueButton";
import {useContext} from "react";
import {AppContext, components} from "../../reducer/contexts";
import {actionTypes} from "../../reducer/actionTypes";

const Home = () => {
    const {dispatch} = useContext(AppContext);

    const handlePlayBtnClick = () => dispatch({
        type: actionTypes.SET_ACTIVE_COMPONENT,
        payload: components.CHAT
    });

    const handleInfoBtnClick = () => dispatch({
        type: actionTypes.SET_ACTIVE_COMPONENT,
        payload: components.INFO
    });

    const handleReferencesBtnClick = () => dispatch({
        type: actionTypes.SET_ACTIVE_COMPONENT,
        payload: components.REFERENCES
    });

    return (
        <>
            <div className="lg:hidden relative flex justify-end !h-5/6">
                <img src={bgMobile} className="!relative background" draggable={false} alt="background"/>
                <img src={doctor} className="absolute bottom-0 right-0 h-[350px]" alt="doctor"/>
                <img src={virtualChat} className="absolute top-[15%] right-6 w-3/4 md:w-3/5" alt="virtual chat"/>
            </div>

            <img src={bg} className="hidden lg:block background" draggable={false} alt="background"/>

            <div className="lg:absolute lg:top-[75%] flex justify-center items-center lg:items-start bg-[#5A1C70] lg:bg-transparent w-full lg:w-3/4 h-1/6 lg:h-full px-8 xl:px-0">
                <BlueButton text="GIOCA" icon="play" onClick={handlePlayBtnClick}/>
                <BlueButton icon="info" onClick={handleInfoBtnClick}/>
                <BlueButton icon="references" onClick={handleReferencesBtnClick}/>
            </div>
        </>
    );
}

export default Home;
