import {createContext} from "react";

export const components = {
    HOME: 'home',
    CHAT: 'chat',
    INFO: 'info',
    REFERENCES: 'references',
}

export const appReducerInitialState = {
    activeComponent: components.HOME,
    isPlaying: false,
    chat: [],
}

export const AppContext = createContext(null);
