import {ThreeDots} from "react-loader-spinner";
import styles from "../../../styles/ChatBox.module.scss";

const ChatBoxPatient = (props) => {
    return (
        <div className={`${styles.chatBox} self-start bg-fucsia lg:mb-14`} onClick={props.onClick}>

            <div className={`${styles.chatBoxArrow} left-[10%] !border-l-0 !border-t-fucsia`}/>

            {!props.isLoaded &&
                <div className="flex items-center h-[1.75rem]">
                    <ThreeDots height="0.75rem" radius="9" color="white"
                               ariaLabel="three-dots-loading"
                               wrapperStyle={{}} wrapperClassName="" visible={true}/>
                </div>
            }

            {props.isLoaded &&
                <p className="text-xl w-full">
                    {props.currentSpeech.text}
                </p>
            }
        </div>
    );
}

export default ChatBoxPatient;
