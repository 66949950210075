import {ThreeDots} from "react-loader-spinner";
import {useContext, useState} from "react";
import {actionTypes} from "../../../reducer/actionTypes";
import {AppContext} from "../../../reducer/contexts";
import styles from "../../../styles/ChatBox.module.scss";

const ChatBoxDoctor = (props) => {
    const {dispatch} = useContext(AppContext);
    const [isQuestion, setIsQuestion] = useState(props.currentSpeech.type === "question");

    const handleChatBoxBtnClick = () => {
        if (isQuestion && props.isLoaded) {
            setIsQuestion(false)

            dispatch({
                type: actionTypes.REMOVE_QUESTION,
                payload: props.index
            });

            dispatch({
                type: actionTypes.ADD_MESSAGE_TO_CHAT,
                payload: {
                    subject: props.currentSpeech.next.subject,
                    index: props.currentSpeech.next.index
                }
            });
        }
    }

    return (
        <div
            className={`${styles.chatBox} ${isQuestion ? styles.chatBoxQuestion : "lg:mb-14"} justify-end bg-purple-dark`}
            onClick={handleChatBoxBtnClick}>

            {!isQuestion && <div className={`${styles.chatBoxArrow} right-[10%] !border-r-0 !border-t-purple-dark`}/>}

            {!props.isLoaded &&
                <div className="flex items-center h-[1.75rem]">
                    <ThreeDots height="0.75rem" radius="9" color={isQuestion ? "#662483" : "white"}
                               ariaLabel="three-dots-loading"
                               wrapperStyle={{}} wrapperClassName="" visible={true}/>
                </div>
            }

            {props.isLoaded &&
                <p className={`text-xl text-end ${isQuestion && "font-bold me-4"} w-full`}>
                    {props.currentSpeech.text}
                </p>
            }
        </div>
    );
}

export default ChatBoxDoctor;
