import {actionTypes} from "./actionTypes";
import {speech} from "../utilis/speech";

const AppReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_ACTIVE_COMPONENT:
            return {
                ...state,
                activeComponent: action.payload,
                chat: [],
            }

        case actionTypes.START_STOP_PLAYING:
            return {
                ...state,
                isPlaying: !state.isPlaying,
                chat: !state.isPlaying ? [] : state.chat,
            }

        case actionTypes.ADD_MESSAGE_TO_CHAT:
            return {
                ...state,
                chat: [...state.chat, action.payload],
            }

        case actionTypes.REMOVE_QUESTION:
            let chat = state.chat;

            if (speech.doctor[action.payload].questionEnd) {
                delete chat[chat.findIndex(item => item?.subject === "doctor" && item?.index === action.payload - 1)];

                if (!speech.doctor[action.payload - 2].questionEnd) {
                    delete chat[chat.findIndex(item => item?.subject === "doctor" && item?.index === action.payload - 2)];
                }
            } else {
                delete chat[chat.findIndex(item => item?.subject === "doctor" && item?.index === action.payload + 1)];

                if (speech.doctor[action.payload + 2].questionEnd) {
                    delete chat[chat.findIndex(item => item?.subject === "doctor" && item?.index === action.payload + 2)];
                }

                if (!speech.doctor[action.payload - 1].questionEnd) {
                    delete chat[chat.findIndex(item => item?.subject === "doctor" && item?.index === action.payload - 1)];
                }
            }

            chat[chat.findIndex(item => item?.subject === "doctor" && item?.index === action.payload)].chosen = true;

            return {
                ...state,
                chat,
            };

        default:
            throw Error('Unknown action.');
    }
}

export default AppReducer;
