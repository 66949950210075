export const speech = {
    patient: [
        {
            id: 0,
            text:
                <>
                    Buongiorno Dottoressa, da qualche tempo ho notato delle secrezioni lattescenti dal capezzolo, cosa
                    può essere?
                </>,
            type: "text",
            next: {
                subject: "doctor",
                index: 0,
            }
        },
        {
            id: 1,
            text:
                <>
                    Da cosa potrebbe essere causata?
                </>,
            type: "text",
            next: {
                subject: "doctor",
                index: 1,
            }
        },
        {
            id: 2,
            text:
                <>
                    No.
                </>,
            type: "text",
            next: {
                subject: "doctor",
                index: 2,
            },
        },
        {
            id: 3,
            text:
                <>
                    Direi non più del solito.
                </>,
            type: "answer",
            next: {
                subject: "doctor",
                index: 6,
            },
        },
        {
            id: 4,
            text:
                <>
                    No.
                </>,
            type: "answer",
            next: {
                subject: "doctor",
                index: 6,
            },
        },
        {
            id: 5,
            text:
                <>
                    No, assolutamente.
                </>,
            type: "answer",
            next: {
                subject: "doctor",
                index: 8,
            },
        },
        {
            id: 6,
            text:
                <>
                    No.
                </>,
            type: "answer",
            next: {
                subject: "doctor",
                index: 8,
            },
        },
        {
            id: 7,
            text:
                <>
                    No.
                </>,
            type: "answer",
            next: {
                subject: "doctor",
                index: 10,
            },
        },
        {
            id: 8,
            text:
                <>
                    No.
                </>,
            type: "answer",
            next: {
                subject: "doctor",
                index: 10,
            },
        },
        {
            id: 9,
            text:
                <>
                    No.
                </>,
            type: "answer",
            next: {
                subject: "doctor",
                index: 12
            },
        },
        {
            id: 10,
            text:
                <>
                    No.
                </>,
            type: "answer",
            next: {
                subject: "doctor",
                index: 12,
            },
        },
        {
            id: 11,
            text:
                <>
                    No.
                </>,
            type: "answer",
            next: {
                subject: "doctor",
                index: 14
            },
        },
        {
            id: 12,
            text:
                <>
                    No, nemmeno.
                </>,
            type: "answer",
            next: {
                subject: "doctor",
                index: 14,
            },
        },
        {
            id: 13,
            text:
                <>
                    Sì, l’ago mi disturba.
                </>,
            type: "text",
            next: {
                subject: "doctor",
                index: 15,
            },
        },
        {
            id: 14,
            text:
                <>
                    La ringrazio Dottoressa, ci rivediamo non appena avrò i risultati.
                </>,
            type: "text",
            next: {
                subject: "doctor",
                index: 16,
            },
        },
    ],
    doctor: [
        {
            id: 0,
            text:
                <>
                    Potrebbe trattarsi di galattorrea, ovvero la produzione di latte in donne che non stanno allattando.<sup>1</sup> Di
                    solito si verifica a un anno dalla gravidanza e dalla fine dell’allattamento al
                    seno.<sup>1</sup> Può verificarsi anche nelle donne nullipare, in post menopausa e persino negli
                    uomini.<sup>1</sup> Per valutarla dobbiamo misurare i livelli di prolattina.<sup>1</sup>
                </>,
            type: "text",
            next: {
                subject: "patient",
                index: 1,
            },
        },
        {
            id: 1,
            text:
                <>
                    Ci possono essere diverse cause, fisiologiche o patologiche.
                    Ad esempio, una delle cause fisiologiche include la stimolazione del capezzolo<sup>1</sup>. Le è
                    capitato
                    ultimamente di vedere delle secrezioni in seguito a questa stimolazione?
                </>,
            type: "text",
            next: {
                subject: "patient",
                index: 2,
            },
        },
        {
            id: 2,
            text:
                <>
                    Quando la galattorrea è associata ad amenorrea, di solito è causata da
                    iperprolattinemia.<sup>1</sup> Sebbene l’incidenza della galattorrea sia variabile, la si può
                    riscontrare in fino al 90% delle donne che soffrono di iperprolattinemia.<sup>1</sup>
                </>,
            type: "text",
            next: {
                subject: "doctor",
                index: 3,
            },
        },
        {
            id: 3,
            text:
                <>
                    Possiamo iniziare ad escludere alcuni fattori che potrebbero causare l’iperprolattinemia.
                </>,
            type: "text",
            next: {
                subject: "doctor",
                index: 4,
            },
        },
        {
            id: 4,
            text:
                <>
                    Per caso è un periodo particolarmente stressante?<sup>2</sup>
                </>,
            type: "question",
            questionEnd: false,
            next: {
                subject: "patient",
                index: 3,
            },
        },
        {
            id: 5,
            text:
                <>
                    Assume anticoagulanti?<sup>2</sup>
                </>,
            type: "question",
            questionEnd: true,
            next: {
                subject: "patient",
                index: 4,
            },
        },
        {
            id: 6,
            text:
                <>
                    Pratica un’attività fisica intensa?<sup>2</sup>
                </>,
            type: "question",
            questionEnd: false,
            next: {
                subject: "patient",
                index: 5,
            },
        },
        {
            id: 7,
            text:
                <>
                    È affetta da diabete?<sup>2</sup>
                </>,
            type: "question",
            questionEnd: true,
            next: {
                subject: "patient",
                index: 6,
            },
        },
        {
            id: 8,
            text:
                <>
                    Assume antidepressivi?<sup>2</sup>
                </>,
            type: "question",
            questionEnd: false,
            next: {
                subject: "patient",
                index: 7,
            },
        },
        {
            id: 9,
            text:
                <>
                    Prende farmaci per contrastare la nausea e il vomito?<sup>2</sup>
                </>,
            type: "question",
            questionEnd: true,
            next: {
                subject: "patient",
                index: 8,
            },
        },
        {
            id: 10,
            text:
                <>
                    Assume anticoagulanti?<sup>2</sup>
                </>,
            type: "question",
            questionEnd: false,
            next: {
                subject: "patient",
                index: 9,
            },
        },
        {
            id: 11,
            text:
                <>
                    L’iperprolattinemia potrebbe dipendere da alcune patologie croniche. Soffre di insufficienza renale
                    o di ipotiroidismo?<sup>2</sup>
                </>,
            type: "question",
            questionEnd: true,
            next: {
                subject: "patient",
                index: 10,
            },
        },
        {
            id: 12,
            text:
                <>
                    È affetta da diabete?<sup>2</sup>
                </>,
            type: "question",
            questionEnd: false,
            next: {
                subject: "patient",
                index: 11,
            },
        },
        {
            id: 13,
            text:
                <>
                    Ha notato se di recente ha avuto anche disturbi alla vista?<sup>2</sup>
                </>,
            type: "question",
            questionEnd: true,
            next: {
                subject: "patient",
                index: 12,
            },
        },
        {
            id: 14,
            text:
                <>
                    Per una corretta diagnosi di iperprolattinemia, come le dicevo, bisogna procedere con un prelievo di
                    sangue. Valori al di sotto dei 25 ng/ml sono da considerarsi normali. Se questi valori risultano
                    superiori, in presenza di iperprolattinemia di origine o causa non definita, si rende necessario lo
                    studio con tecniche di imaging della regione ipotalamo-ipofisaria.<sup>2, 3</sup>
                    Solitamente quando si sottopone ad un prelievo di sangue è ansiosa?
                </>,
            type: "text",
            next: {
                subject: "patient",
                index: 13,
            },
        },
        {
            id: 15,
            text:
                <>
                    Questo dato è molto rilevante perché lo stress da puntura con ago può alterare i livelli di
                    prolattina.<sup>2</sup> In questi casi, la determinazione iniziale della prolattina sierica dovrebbe
                    essere effettuata evitando uno stress eccessivo da venipuntura.<sup>2</sup> Solitamente un singolo
                    prelievo è sufficiente, ma in caso di dubbi, il prelievo può essere ripetuto in un giorno diverso a
                    intervalli di 15-20 minuti per tenere conto della possibile pulsatilità della
                    prolattina.<sup>2</sup> Di questo, può avvisare il laboratorio analisi.
                </>,
            type: "text",
            next: {
                subject: "patient",
                index: 14,
            },
        },
        {
            id: 16,
            text:
                <>
                    A presto.
                </>,
            type: "text",
        },
    ],
}
