import playIcon from "../../assets/images/icons/play_icon.png";
import infoIcon from "../../assets/images/icons/info_icon.png";
import referencesIcon from "../../assets/images/icons/references_icon.png";
import homeIcon from "../../assets/images/icons/home_icon.png";

const BlueButton = (props) => {
    return (
        <button
            className={`flex justify-center items-center text-white bg-blue rounded-full ${props.icon === "play" ? "w-4/6 md:w-2/5 lg:w-[22%]" : "w-[50px] lg:w-auto aspect-square"} ${props.icon === "info" && "mx-5"} ${props.className} h-[50px] lg:h-[6%]`}
            onClick={props.onClick}>
            <span className="text-2xl font-bold leading-none">{props.text}</span>
            {props.icon && <img src={props.icon === "play" ? playIcon : props.icon === "info" ? infoIcon : props.icon === "references" ? referencesIcon : homeIcon} className={`${props.icon === "play" && "ps-4"} h-1/2`} alt={`${props.icon} icon`}/>}
        </button>
    );
};

export default BlueButton;
