import {AppContext, appReducerInitialState, components} from "./reducer/contexts";
import AppReducer from "./reducer/appReducer";
import {useReducer} from "react";
import Home from "./components/pages/Home";
import Chat from "./components/pages/Chat";
import Info from "./components/pages/Info";
import References from "./components/pages/References";
import styles from "./styles/App.module.scss";
import {code} from "./utilis/common";
import logo from "./assets/images/logo.png";

const App = () => {
    const [state, dispatch] = useReducer(AppReducer, appReducerInitialState);

    return (
        <AppContext.Provider value={{state, dispatch}}>
            <div id={styles.appContainer} className="relative shadow-lg shadow-[rgba(0,0,0,0.4)] max-w-[1920px] lg:aspect-video overflow-hidden h-full lg:h-auto lg:my-auto lg:mx-auto">
                {state.activeComponent === components.HOME && <Home/>}
                {state.activeComponent === components.CHAT && <Chat/>}
                {state.activeComponent === components.INFO && <Info/>}
                {state.activeComponent === components.REFERENCES && <References/>}

                {state.activeComponent !== components.CHAT &&
                    <>
                        <img src={logo}
                             className="absolute top-[2%] lg:top-[5%] left-[2%] lg:left-auto lg:right-[5%] w-[100px]"
                             alt="logo pfizer"/>

                        <p className="absolute bottom-2 lg:bottom-5 left-2 lg:left-5 text-md text-white leading-none">{code}</p>
                    </>
                }
            </div>
        </AppContext.Provider>
    );
}

export default App;
