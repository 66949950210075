import bg from "../../assets/images/bg_info.png";
import BlueButton from "../partials/BlueButton";
import {AppContext, components} from "../../reducer/contexts";
import {useContext} from "react";
import {actionTypes} from "../../reducer/actionTypes";
import top from "../../assets/images/top_info.png";
import bottom from "../../assets/images/bottom_info.png";
import doctor from "../../assets/images/doctor.png";
import virtualChat from "../../assets/images/virtual_chat_info.png";
import styles from "../../styles/Info.module.scss";

const Info = () => {
    const {dispatch} = useContext(AppContext);
    const handleCloseBtnClick = () => dispatch({
        type: actionTypes.SET_ACTIVE_COMPONENT,
        payload: components.HOME
    });

    return (
        <div className="flex justify-center lg:justify-start bg-white lg:bg-transparent">
            <img src={top} className="lg:hidden absolute top-0 w-full h-borer-info" alt="top border"/>
            <img src={bottom} className="lg:hidden absolute bottom-0 w-full h-borer-info" alt="bottom border"/>

            <img src={doctor} className="lg:hidden absolute bottom-0 right-5 h-[200px]" alt="doctor"/>

            <img src={bg} className="hidden lg:block background" draggable={false} alt="background"/>

            <BlueButton text="✕" className="absolute top-[55px] lg:top-[10%] right-[5%] lg:left-[24.5%]"
                        onClick={handleCloseBtnClick}/>

            <div
                className="w-full lg:w-[29%] px-4 mt-[150px] lg:mt-[7%] lg:ms-[28%]">
                <div className="flex justify-end">
                    <img src={virtualChat} className="h-[200px] lg:w-[80%] lg:h-auto" alt="virtual chat"/>
                </div>

                <div id={styles.textContainer}
                     className="overflow-auto mt-8 lg:mt-[10%] lg:!h-[50%] xl:!h-[52%] 2xl:!h-[60%]">
                    <p className="xl:text-lg 2xl:text-xl lg:text-end pb-[180px] lg:pb-0">
                        Come reagisci alle domande dei pazienti sull’iperprolattinemia? In questa simulazione come in
                        una reale visita, il paziente parte dal racconto dei sintomi e della sua esperienza, ma quali
                        domande dello specialista – tra le tante possibili – sono veramente utili per orientare la
                        conversazione nel verso giusto?<br/>
                        Giocare è molto semplice. Dopo ciascuno scambio di battute medico-paziente, comparirà un elenco
                        di domande da fare al paziente: scegli quella che ti sembra più adatta a proseguire il dialogo e
                        scopri se sei nella direzione giusta.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Info;
